const Index=()=>import("../views/Index");
const aboutUs=()=>import("../views/aboutUs.vue");
const aboutYanfa=()=>import("../views/aboutYanfa");
const contactUs=()=>import("../views/contactUs");
const xingzaiShimmer=()=>import("../views/xingzaiShimmer");
const news=()=>import("../views/news");
const kePu=()=>import("../views/DiseaseSciencePopularization");
const xunZheng=()=>import("../views/xunZheng");
const detail=()=>import("../views/xunzhengDetail");
const shiZhan=()=>import("../views/shiZhan");
const product=()=>import("../views/product");
const productDetail=()=>import("../views/productDetail");
const newsdetail=()=>import("../views/newsDetail");
const reFlashPage=()=>import("../views/reFlashPage");

export default [{
        path: '/', //路由路径
        redirect: 'index/index/index'
    },
    {
        path: '/index/index/index', //首页
        name: 'index', //路由名字
        component: Index, //路由指向的页面
    },
    {
        path: '/index/about/index', //关于我们
        name: 'aboutUs',
        component: aboutUs,
    },
    {
        path: '/index/about/yanFa', //关于我们研发
        name: 'aboutYanfa',
        component: aboutYanfa,
    },
    {
        path: '/index/about/contact', //联系我们
        component: contactUs,
    },
    {
        path: '/index/xing/index', //行在微光
        component: xingzaiShimmer,
    },
    {
        path: '/index/news/index', //新闻动态
        component: news,
    },
    {
        path: '/index/ill/kePu', //疾病科普
        component: kePu,
    },
    {
        path: '/index/ill/case', //实战
        component: shiZhan,
    },
    {
        path: '/index/doc/index', //循证解读
        component: xunZheng,
    },
    {
        path: '/index/doc/illDocDetail', //循证解读
        component: detail,
    }, {
        path: '/index/doc/newsDocDetail', //循证解读
        component: newsdetail,
    },
    {
        path: '/index/product/index', //产品中心
        component: product,
    },
    {
        path: '/index/product/detail.html', //产品中心详情
        component: productDetail,
    },
    {
        path: '/reFlashPage', //产品中心
        component: reFlashPage,
    }

]
