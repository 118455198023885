import request from '@/utils/request'

let basePath1 = process.env.VUE_APP_BASE_API1
    // let basePath2 = process.env.VUE_APP_BASE_API2
    // let basePath3 = process.env.VUE_APP_BASE_API3

//发短信验证码
// export function sendSmsApi(query) {
//     return request({
//         url: 'business/send',
//         method: 'get',
//         params:query
//     })
// }


//获取数据
export function GetYear() {
    return request({
        url: basePath1 + '/en/api/getYears?type=1',
        method: 'get',
    })
}
//获取版权，meta数据
export function getMeta() {
    return request({
        url: basePath1 + 'en/api/getMeta',
        method: 'get',
    })
}
//获取数据
export function GetIndexDoc(data) {
    console.log(data);
    return request({
        url: basePath1 + '/en/api/getDoc?from=m&page_index=' + data,
        method: 'get',
    })
}
//获取轮播
export function GetLunbo() {
    return request({
        url: basePath1 + '/en/api/getShuffling?place=1',
        method: 'get',
    })
}

//获取菜单
export function getMenu() {
    return request({
        url: basePath1 + 'en/api/nav?from=m',
        method: 'get',
    })
}
//获取关于微光历程
export function GetAboutLicheng(data) {
    return request({
        url: basePath1 + 'en/api/licheng?from=m&year=' + data,
        method: 'get',
    })
}
//获取关于微光荣誉
export function GetAboutRongyu(data) {
    return request({
        url: basePath1 + 'en/api/rongyu?from=m&year=' + data,
        method: 'get',
    })
}
//获取视频播报
export function GetAboutVideo() {
    return request({
        url: basePath1 + 'en/api/shipin?from=m&page=1',
        method: 'get',
    })
}
//获取关于微光荣誉
export function GetXingPics() {
    return request({
        url: basePath1 + 'en/api/xing?from=m',
        method: 'get',
    })
}
//获取新闻动态
export function GetNewsData(data) {
    console.log(data);
    return request({
        url: basePath1 + 'en/api/news?from=m',
        method: 'get',
        params: data
    })
}
//获取新闻动态
export function GetDiseaseKepu() {
    return request({
        url: basePath1 + 'en/api/illKnowledge?id=35&from=m',
        method: 'get',
    })
}
//获取新闻动态
export function GetShizhanData(data) {
    return request({
        url: basePath1 + 'en/api/illVideo?from=m',
        method: 'get',
        params: data

    })
}
//获取新闻动态
export function GetXunzhengData() {
    return request({
        url: basePath1 + 'en/api/illDoc?page=1&from=m',
        method: 'get'

    })
}
//获取询证详情
export function GetXunzhengDetail(data) {
    return request({
        url: basePath1 + 'en/api/illDocDetail?from=m&id=' + data,
        method: 'get'

    })
}
//获取新闻动态详情
export function GetNewsDetail(data) {
    return request({
        url: basePath1 + 'en/api/newsDetail?from=m&id=' + data,
        method: 'get'

    })
}
//获取产品列表1
export function GetProductData1() {
    return request({
        url: basePath1 + 'en/api/productCate?from=m&type=1',
        method: 'get'
    })
}
//获取产品信息
export function GetProductDetailData(data) {
    return request({
        url: basePath1 + 'en/api/productDetail?from=m&id=' + data,
        method: 'get'
    })
}
//获取产品信息
export function GetAddressData() {
    return request({
        url: basePath1 + '/en/api/getAddress',
        method: 'get'
    })
}