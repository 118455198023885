import Vue from 'vue'
import App from './App.vue'
import router from './router/'
import './plugins/vant.js'
import './plugins/element'
import '@/assets/css/font.css'
import '@/assets/css/public.css'
import '@/assets/css/commen.css'
import Constant from '@/utils/constant'
import '@/assets/css/animate.min.css'
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
import VueTouch from 'vue-touch';
import VueLazyload from 'vue-lazyload'
import Meta from "vue-meta";





import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)
Vue.use(VueLazyload)
Vue.use(VueTouch, {name: 'v-touch'});
Vue.use(Meta);

Vue.config.productionTip = false
Vue.prototype.$myConstant = Constant;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
